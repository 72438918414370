<template>
  <section id="member_data_info">
    <div class="container w1300">
      <div class="fliter_outer">
        <MenuClassificationFilter
          :Classification="Classification"/>
      </div>
      <div class="info_outer">
        <ul class="member_info" v-if="memberProfile">
          <li class="info_item">
            <p class="small">
              <span class="t txt-medium">{{$t('name')}} |</span>
              <span>{{memberProfile.name}}</span>
            </p>
            <p class="small txt-theme">
              <span class="t txt-medium">{{$t('patientNo')}} |</span>
              <span>{{memberProfile.nuwa_patient_no}}</span>
            </p>
          </li>
          <!-- <li class="info_item">
            <p class="small">
              <span class="t txt-medium">性別 |</span>
              <span>{{memberProfile.sex === 'm' ? '男性' : '女性'}}</span>
            </p>
          </li> -->
          <li class="info_item">
            <p class="small">
              <span class="t txt-medium">{{$t('phoneNumber')}} |</span>
              <span>{{`${memberProfile.country_code} ${memberProfile.phone}`}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span class="t txt-medium">{{$t('birthDay')}} |</span>
              <span>{{memberProfile.birthday}}</span>
            </p>
          </li>
          <li class="info_item">
            <p class="small">
              <span v-if="isTaiwanId" class="t txt-medium">{{$t('memberDataPid')}} |</span>
              <span v-else class="t txt-medium">{{$t('pidAndPassportNum')}} |</span>
              <span>{{memberProfile.account}}</span>
            </p>
          </li>
          <li class="info_item">
            <!-- <div class="change_item">
              <p class="small txt-medium">信箱</p>
              <button type="button" class="change_btn"
                @click="openChangeEmailLightbox();">
                <small>{{memberProfile.email}}</small>
              </button>
            </div> -->
            <div class="change_item">
              <p class="small txt-medium">{{$t('password')}}</p>
              <button type="button" class="change_btn"
                @click="changePasswordLightboxOpen = true;">
                <small>****</small>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div id="change_password_lightbox"
      v-show="changePasswordLightboxOpen">
      <div class="cover" @click="changePasswordLightboxOpen = false;"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            {{$t('changePassword')}}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <p>{{$t('newPasswordInfo')}}</p>
          <form class="">
            <p class="small">{{$t('oldPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee1) ? 'text' : 'password'"
                @blur="passwordTest1"
                v-model="oldPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee1 = !ifPasswordAbleToSee1">
            </div>
            <p class="small">{{$t('newPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee2) ? 'text' : 'password'"
                maxlength="16"
                @blur="passwordTest2"
                v-model="newPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee2 = !ifPasswordAbleToSee2">
            </div>
            <p class="small">{{$t('comfirmNewPassword')}}</p>
            <div class="password_outer">
              <input
                :type="(ifPasswordAbleToSee3) ? 'text' : 'password'"
                maxlength="16"
                @blur="passwordTest3"
                v-model="checkNewPassword">
              <img src="@/assets/img/eye.jpg" alt="" class="eye"
                @click="ifPasswordAbleToSee3 = !ifPasswordAbleToSee3">
            </div>
          </form>
          <button type="button"
            class="btn p"
            @click="clickToChangePassword();">
            {{$t('saveMod')}}
          </button>
        </div>
      </div>
    </div>

    <!-- <div id="change_email_lightbox"
      v-show="changeEmailLightboxOpen">
      <div class="cover" @click="clickCloseChangeEmailLightbox()"></div>
      <div class="box">
        <div class="text txt-center">
          <h5 class="txt-bold">
            變更信箱
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="">
          </h5>
          <form class="">
            <p class="small">輸入新信箱</p>
            <input type="text">
          </form>
          <button type="button"
            class="btn p"
            @click="clickCloseChangeEmailLightbox()">
            儲存修改
          </button>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import '@/assets/scss/member.scss';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';

import { changePassword } from '@/lib/public';

export default {
  name: 'MemberDatainfo',
  components: {
    MenuClassificationFilter,
  },
  props: {
    Classification: Array,
    memberProfile: null,
    isTaiwanId: Boolean,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      checkNewPassword: '',
      changePasswordLightboxOpen: false,
      // changeEmailLightboxOpen: false,
      ifPasswordAbleToSee1: false,
      ifPasswordAbleToSee2: false,
      ifPasswordAbleToSee3: false,
    };
  },
  methods: {
    passwordTest1() {
      if (this.oldPassword.length < 8) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordLengthError') });
        this.oldPassword = '';
      }
    },
    passwordTest2() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.newPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.newPassword = '';
      }
    },
    passwordTest3() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (!passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
        this.checkNewPassword = '';
      }
    },
    clickToChangePassword() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z0-9]{8,}$/;
      if (this.newPassword !== this.checkNewPassword) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordComfirmError') });
      } else if (!this.oldPassword) {
        this.$customSWAL({ icon: 'error', title: this.$t('inputOldPassword') });
      } else if (!passwordRegex.test(this.newPassword) || !passwordRegex.test(this.checkNewPassword)) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordComfirmError') });
      } else {
        changePassword(
          this.$cookies.get('api_token'),
          {
            old_password: this.oldPassword,
            password: this.newPassword,
          },
          () => {
            this.$customSWAL({
              title: this.$t('modifySuccess'),
              thenFunc: () => { this.changePasswordLightboxOpen = false; },
            });
            this.oldPassword = '';
            this.newPassword = '';
            this.checkNewPassword = '';
          },
          (message) => {
            this.$customSWAL({ icon: 'error', title: message });
          },
        );
      }
    },
  },
};
</script>
